import { Injectable } from '@angular/core';
import { Reserva } from '../interfaces/reserva';
import { Router } from '@angular/router';
import { Tarifa } from '../interfaces/tarifa';
import { HttpClient } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { baseurl, headers } from '../enum/config';
import { Cliente } from '../interfaces/cliente';
import { Transportacion } from '../interfaces/transportacion';
import { TipoServicios } from '../enum/tipoServicios';


@Injectable({
  providedIn: 'root'
})
export class ReservaService {

  reserva:Reserva = {};
  tarifa:Tarifa = {};
  cliente:Cliente ={};
  transportacion:Transportacion = {};

  constructor(private router:Router,private http:HttpClient) { }

  setStep1(tipoTransfer:string,hotel:string,fecha_reserva:Date,adultos:number,menores:number){
    this.transportacion.tipoServicio=tipoTransfer;
    this.transportacion.hotel = hotel;
    this.reserva.pax = {
      adultos,
      menores
    };
    this.getTarifa().subscribe(
      (res:any)=>{
        this.setTarifa(res.tarifa);
        this.router.navigate(['transfers']);
      }
    );
    
  }
  async setCliente(cliente:Cliente){
    this.cliente=cliente;
    this.reserva.cliente = this.cliente;
  }
  setTarifa(tarifa:Tarifa){
    this.tarifa = tarifa;
    console.log(this.tarifa);
    return this.tarifa;
  }
  async setTransfer(fullService:any){
    switch(this.transportacion.tipoServicio){
      case TipoServicios.HotelAeropuerto:
      case TipoServicios.AeroPuertoHotel:
      case TipoServicios.RoundTrip:
      this.transportacion.origen = this.tarifa.origen;
      this.transportacion.destino = this.tarifa.destino;

      this.transportacion.servicio = {
        fecha_vuelo:fullService.fecha_vuelo,
        hora_vuelo:fullService.hora_vuelo,
        aerolinea:fullService.aerolinea,
        vuelo:fullService.vuelo,
        fecha_vuelo_salida:fullService.fecha_vuelo_salida || undefined,
        hora_vuelo_salida:fullService.hora_vuelo_salida || undefined,
        aerolinea_salida:fullService.aerolinea_salida || undefined,
        vuelo_salida:fullService.vuelo_salida || undefined
      }

      break;
    }


  }

  async setReserva(comentarios?:string){
    let url = baseurl+'reservas/next';
    this.http.get(url,{headers}).subscribe((res:any)=>{
      this.reserva.id = res.next;
      this.reserva.codigo = 'UCM00' + this.reserva.id;
      this.reserva.comentarios = comentarios || undefined;
      this.reserva.servicio = this.transportacion;
      this.reserva.tarifa = this.tarifa;
    });
  }

  getTarifa():Observable<any>{
    let url = baseurl+'hoteles/tarifa/'+this.transportacion.hotel+'/servicio/'+this.transportacion.tipoServicio;
    url=url.replace(" ",'%20');
    return this.http.get(url,{headers:headers});
  }

  sendReserva():Observable<any>{
    let url = baseurl+'reservas';
    return this.http.post(url,this.reserva,{headers});
  }
}
