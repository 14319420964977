import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router';
import { ReservaService } from '../servicios/reserva.service';

@Injectable({
  providedIn: 'root'
})
export class TransfersGuard implements  CanActivate{

  constructor(private reservaService:ReservaService, private router: Router){

  }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    let tarifa = this.reservaService.tarifa;
    console.log("Tarifa",tarifa.precio);
    if(tarifa.precio===undefined){
      this.router.navigate(['/'])
      return false;
    }
    return true;
  }

}
