import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TransfersGuard } from './transfers/transfers.guard';

const routes: Routes = [
  {path:'',loadChildren:'./home/home.module#HomeModule'},
  {path:'transfers',loadChildren:'./transfers/transfers.module#TransfersModule',canActivate:[TransfersGuard]},
  {path:'tours',loadChildren:'./tours/tours.module#ToursModule'},
  {path:'destinos',loadChildren:'./destinos/destinos.module#DestinosModule'},
  {path:'contacto',loadChildren:'./contacto/contacto.module#ContactoModule'},
  {path:'galeria',loadChildren:'./galeria/galeria.module#GaleriaModule'},
  {path:'nosotros',loadChildren:'./nosotros/nosotros.module#NosotrosModule'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
